





























































  import{ Component, Vue }from 'vue-property-decorator';
  import CusTable from '@/components/table';

  @Component({
		name: 'role'
	})
  export default class Role extends Vue {
		searchVal = '';
		private isDrawer = false;
		private AddDialogVisible = false;
		inputVal = '';
		private currentRow:any = null;
		public cusTableKey = Date.now();
		private RoleData = [];
		private defaultChecked = [];
		private defaultExpandNodes = []
    $refs!: { table: CusTable, tree}

		private affirm() {
			this.currentRow ? this.modifyRole() : this.addRole();
		};

		private addRole() {
      this.axios.post('/permission/role/add', {name: this.inputVal}).then((res: any) => {
        if (res.result) this.$message.success('新增角色成功');
        this.AddDialogVisible = false;
        this.cusTableKey = Date.now();
      });
		};

		private modifyRole() {
      this.axios.post('/permission/role/modify', {name: this.inputVal, id: this.currentRow.id}).then((res: any) => {
        if (res.result) this.$message.success('修改角色成功');
        this.AddDialogVisible = false;
        this.cusTableKey = Date.now();
      });
		}

		private openDetail(row) {
		  this.currentRow = row;
		  this.isDrawer = true;
		  this.axios.post('/permission/roleMenu/queryAll', {roleId: row.id}).then((res: any) => {
		    if (res.json) this.defaultChecked = res.json.map(item => item.menuId);
		    if (!res.json) this.defaultChecked = [];
			  (this.$refs['tree'] as any).setCheckedKeys(this.defaultChecked);
		  })
		}

		private async remove({id}) {
      let confirm = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', { type: 'warning' });
      if (confirm) {
        const res: any = await this.axios.post('/permission/role/delete', {id});
        res.result && this.$message.success('删除角色成功~！');
	      this.cusTableKey = Date.now()
      };
		};

		private state({id, status}) {
		  const res: any = this.axios.post('/permission/role/modify', {id, status: (status == 1 ? 0 : 1)});
		  if (res.result) this.$message.success('修改状态成功~！');
      this.cusTableKey = Date.now()
    };

		private handleCheckChange(data, checkData) {
			let menuList: any[] = this.$refs.tree.getCheckedKeys()
			if(!menuList.includes('/home')) menuList.push('/home')
			if(!menuList.includes('/index')) menuList.push('/index')
			this.axios.post('/permission/roleMenu/add', {roleId: this.currentRow.id, menuIds: menuList.toString()}).then((res: any) => {
			  if (res.result) {
				this.$message.success('修改角色权限成功~!')
				this.userRole()
			  }
			})
		}

		private userRole() {
			this.axios.get('/permission/userRole/getRole', {}).then((res: any) => {
			  if (res.result) {
				console.log(res.json)
				localStorage.setItem('axbp_roles',  res.json[0]);
				this.$bus.$emit('USERROLE', res.json[0]);
			  }
			})
		}

		timer: null | number = null;
		inputValChange(e) {
			if (this.timer) clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.cusTableKey = Date.now();
			}, 600)
		}
		/**
		 * @func 权限设置 - 不显示首页 默认勾选
		 */
		private getMenuTree() {
			this.axios.post('/permission/menu/queryAllMenuTree').then((res: any) => {
				if (res.result) {
					// this.RoleData = res.json;
					this.RoleData = res.json && res.json.map(i => {
						if(i.childs) {
							i.childs = i.childs.filter(k => k.url !== '/home' && k.url !== '/index')
						}
						return i
					});
				}
			})
    	};

		private created() {
		  this.getMenuTree();
		}


		private __click_handle(Visible) {
        this.isDrawer = Visible
		}
  }
